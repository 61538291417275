import React from "react";
import Layout from "../components/layout";
import { Link, Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Layout>        
        <h1><Trans i18nKey="PrivacyPage.H1_Privacy"></Trans></h1>
        <Trans i18nKey="PrivacyPage.P_Privacy"></Trans>
          <ul>
            <li>
              <Trans i18nKey="PrivacyPage.LI_Firefox"></Trans>
            </li>
            <li>
              <Trans i18nKey="PrivacyPage.LI_Safari"></Trans>
            </li>
            <li>
              <Trans i18nKey="PrivacyPage.LI_Chrome"></Trans>
            </li>
          </ul>
        <Trans i18nKey="PrivacyPage.P_DoNotSellData"></Trans>
      </Layout>
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;